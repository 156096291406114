/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ApplicationProcessingErrorReasonDto {
    INVALID_ADDRESS = 'INVALID_ADDRESS',
    DUPLICATED = 'DUPLICATED',
    DUPLICATED_COMMUNICATION_FAILURE = 'DUPLICATED_COMMUNICATION_FAILURE',
    CREDIT_INSTITUTION = 'CREDIT_INSTITUTION',
    CREDIT_INSTITUTION_SEARCH_FAILED = 'CREDIT_INSTITUTION_SEARCH_FAILED',
    CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE',
}
